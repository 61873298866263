<template>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">経歴</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field
          label="業種"
          :type="hasError('project.target_industry')"
          :message="message('project.target_industry')"
        >
          <b-input
            type="text"
            icon="palette"
            v-model="form.project.target_industry"
          ></b-input>
        </b-field>
        <b-field
          label="活動"
          :type="hasError('project.activity')"
          :message="message('project.activity')"
        >
          <b-input
            type="text"
            icon="keyboard-outline"
            v-model="form.project.activity"
          ></b-input>
        </b-field>
        <b-field
          label="開始"
          :type="hasError('started_on')"
          :message="message('started_on')"
        >
          <b-datepicker
            icon="calendar-start"
            locale="ja"
            v-model="form.started_on"
          ></b-datepicker>
        </b-field>
        <b-field
          label="終了"
          :type="hasError('finished_on')"
          :message="message('finished_on')"
        >
          <b-datepicker
            icon="calendar-end"
            locale="ja"
            v-model="form.finished_on"
          ></b-datepicker>
        </b-field>
        <b-field
          label="参画者数"
          :type="hasError('project.number_of_participants')"
          :message="message('project.number_of_participants')"
        >
          <b-input
            type="number"
            icon="account-group"
            value=""
            min="1"
            v-model="form.project.number_of_participants"
          ></b-input>
        </b-field>
        <b-field
          label="役割区分"
          :type="hasError('role_code')"
          :message="message('role_code')"
        >
          <b-radio-button
            native-value="1"
            type="is-success is-light"
            expanded
            v-model="form.role_code"
          >
            <b-icon icon="account"></b-icon>
            <span>担当者</span>
          </b-radio-button>

          <b-radio-button
            v-model="form.role_code"
            native-value="2"
            type="is-warning is-light"
            expanded
          >
            <b-icon icon="account-star"></b-icon>
            <span>管理者</span>
          </b-radio-button>
        </b-field>
        <b-field
          label="担当作業"
          :type="hasError('experienced_tasks')"
          :message="message('experienced_tasks')"
        >
          <b-taginput
            autocomplete
            icon="tag-plus"
            open-on-focus
            :data="tasks"
            field="label"
            v-model="form.experienced_tasks"
          >
          </b-taginput>
        </b-field>
        <div
          v-for="(items, attribute) in form.project_components"
          :key="attribute"
        >
          <fieldset>
            <legend class="is-size-7 mb-2">
              構成({{ displayProjectComponentAttribute(attribute) }})
            </legend>
            <b-field
              grouped
              v-for="(item, index) in items"
              :key="item.attribute + '-' + index"
              :type="item.$delete ? 'is-danger' : ''"
            >
              <b-field
                label="名称"
                expanded
                :type="hasError('project_components.*.name')"
                :message="message('project_components.*.name')"
              >
                <b-input v-model="item.name" :disabled="item.$delete"></b-input>
              </b-field>
              <b-field label="ver." expanded>
                <b-input
                  v-model="item.version"
                  :disabled="item.$delete"
                ></b-input>
              </b-field>
              <b-button
                :type="
                  item.$delete ? 'is-primary is-light' : 'is-danger is-light'
                "
                :icon-left="item.$delete ? 'undo' : 'minus'"
                @click="removeComponent(item.attribute, index)"
              />
            </b-field>
            <b-button
              type="is-primary is-light"
              icon-left="plus"
              size="is-small"
              @click="addComponent(attribute)"
            />
          </fieldset>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button label="キャンセル" @click="$emit('close')" expanded />
        <b-button
          label="保存"
          type="is-primary"
          expanded
          @click="save"
          :loading="storing"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import storeMixin, { clone, formatDateField } from "../store-mixin";
import { EXPERIENCED_TASK, PROJECT_COMPONENT_ATTRIBUTE } from "../constants.js";
import dayjs from "dayjs";

function buildForm(value) {
  const {
    started_on,
    finished_on,
    role_code,
    experienced_tasks,
    project_components,
    ...form
  } = clone(value);
  form.started_on = started_on === null ? null : dayjs(started_on).toDate();
  form.finished_on = finished_on === null ? null : dayjs(finished_on).toDate();
  form.role_code = role_code.toString();
  form.experienced_tasks = Object.values(EXPERIENCED_TASK).filter((v) =>
    experienced_tasks.includes(v.value)
  );
  form.project_components = Object.values(PROJECT_COMPONENT_ATTRIBUTE).reduce(
    (a, c) => {
      a[c.value] = project_components.filter((v) => +v.attribute === +c.value);
      return a;
    },
    {}
  );
  return form;
}

function initialForm(value) {
  if (value === undefined || value === null) {
    const project_components = Object.values(
      PROJECT_COMPONENT_ATTRIBUTE
    ).reduce((a, c) => {
      a[c.value] = [];
      return a;
    }, {});
    return {
      id: null,
      started_on: null,
      finished_on: null,
      role_code: null,
      experienced_tasks: [],
      project: {
        id: null,
        target_industry: null,
        activity: null,
        number_of_participants: null,
      },
      project_components,
    };
  }

  return buildForm(value);
}

export default {
  name: "ResumeForm",
  mixins: [storeMixin],
  props: {
    value: Object,
  },
  data() {
    return {
      form: initialForm(this.value),
    };
  },
  watch: {
    value(value) {
      this.form = initialForm(value);
    },
  },
  computed: {
    tasks() {
      return Object.values(EXPERIENCED_TASK);
    },
  },
  methods: {
    displayProjectComponentAttribute(value) {
      return Object.values(PROJECT_COMPONENT_ATTRIBUTE).find(
        (v) => +v.value === +value
      ).label;
    },
    addComponent(attribute) {
      const newComponent = {
        id: null,
        attribute: parseInt(attribute),
        name: null,
        version: null,
      };
      if (this.form.project_components[attribute] === undefined) {
        this.$set(this.form.project_components, attribute, [newComponent]);
      } else {
        this.form.project_components[attribute].push(newComponent);
      }
    },
    removeComponent(attribute, index) {
      const comp = this.form.project_components[attribute][index];
      if (comp.id === null) {
        this.form.project_components[attribute].splice(index, 1);
      } else {
        this.$set(comp, "$delete", !comp.$delete);
      }
    },
    async save() {
      try {
        const {
          started_on,
          finished_on,
          role_code,
          experienced_tasks,
          project_components,
          ...form
        } = clone(this.form);
        form.started_on = formatDateField(started_on);
        form.finished_on = formatDateField(finished_on);
        form.role_code = parseInt(role_code, 10);
        form.experienced_tasks = experienced_tasks.map((v) => v.value);
        form.project_components = Object.values(PROJECT_COMPONENT_ATTRIBUTE)
          .map((v) => project_components[v.value])
          .flat()
          .filter((v) => v.$delete !== true);

        if (form.id === null) {
          await this.store("my/addWorkExperience", form);
        } else {
          await this.store("my/updateWorkExperience", {
            workExperienceId: form.id,
            requestBody: form,
          });
        }
        this.$emit("close");
        this.form = initialForm(this.value);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
