<template>
  <div class="container is-max-desktop">
    <nav class="level is-mobile mx-2">
      <div class="level-left">
        <div class="level-item">
          <b-button type="is-primary" icon-left="text-box-plus" @click="add"
            >経歴追加</b-button
          >
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button
            type="is-primary is-light"
            icon-left="download"
            @click="download"
            >ダウンロード</b-button
          >
        </div>
      </div>
    </nav>

    <section class="m-2">
      <b-loading :is-full-page="false" :value="loading || storing"></b-loading>
      <b-collapse
        class="card mb-1"
        animation="slide"
        :open="false"
        v-for="experience in workExperiences"
        :key="experience.id"
      >
        <template #trigger="props">
          <header class="card-header" role="button">
            <p class="resume card-header-title">
              <b-tag
                :type="displayRoleCodeType(experience.role_code) + ' is-light'"
              >
                {{ displayRoleCode(experience.role_code) }}
              </b-tag>
              <small>
                {{ displayDate(experience.started_on) }} ～
                {{ displayDate(experience.finished_on) }} ({{
                  displayDuration(experience)
                }}ヵ月) </small
              ><br />
              <small class="has-text-grey-light">{{
                experience.project.target_industry
              }}</small
              ><br />
              <span :class="props.open ? 'has-text-primary' : ''">{{
                experience.project.activity
              }}</span>
            </p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'">
              </b-icon>
            </a>
          </header>
        </template>
        <div class="card">
          <div class="card-content">
            <div class="content">
              <p>参画者数: {{ experience.project.number_of_participants }}名</p>
              <h5 class="is-size-6 has-text-weight-bold">担当作業</h5>
              <b-taglist>
                <b-tag
                  :type="'is-light ' + displayTaskType(task)"
                  rounded
                  v-for="task in experience.experienced_tasks"
                  :key="task"
                >
                  {{ displayTaskName(task) }}
                </b-tag>
              </b-taglist>
              <h5 class="is-size-6 has-text-weight-bold">構成</h5>
              <table class="table is-narrow is-fullwidth">
                <tbody>
                  <tr v-for="attr in componentAttributes" :key="attr.value">
                    <th>{{ attr.label }}</th>
                    <td>{{ displayComponentItem(experience, attr.value) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <a class="card-footer-item" @click="edit(experience)">編集</a>
            <a class="card-footer-item" @click="remove(experience)">削除</a>
          </div>
        </div>
      </b-collapse>
    </section>
    <div class="m-4">
      <b-button
        type="is-primary"
        icon-left="text-box-plus"
        expanded
        @click="add"
        >経歴追加</b-button
      >
    </div>
    <b-modal
      v-model="isFormOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="['escape']"
    >
      <template #default="props">
        <resume-form @close="props.close" :value="form"></resume-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import fetchMixin from "../fetch-mixin";
import storeMixin from "../store-mixin";
import ResumeForm from "../components/ResumeForm";
import {
  ROLE_CODE,
  EXPERIENCED_TASK,
  PROJECT_COMPONENT_ATTRIBUTE,
} from "../constants";

export default {
  name: "Resume",
  mixins: [fetchMixin, storeMixin],
  components: { ResumeForm },
  data() {
    return {
      form: null,
      isFormOpen: false,
    };
  },
  props: {},
  computed: {
    componentAttributes() {
      return Object.values(PROJECT_COMPONENT_ATTRIBUTE);
    },
    workExperiences() {
      return Object.values(this.$store.state.my.work_experiences).sort((a, b) =>
        a.started_on.localeCompare(b.started_on)
      );
    },
  },
  methods: {
    displayDate(date) {
      if (date === null) {
        return "現在";
      } else {
        return dayjs(date).format("YYYY/M/D");
      }
    },
    displayDuration(experience) {
      const end =
        experience.finished_on === null
          ? dayjs()
          : dayjs(experience.finished_on);
      return end.add(1, "day").diff(experience.started_on, "months");
    },
    displayRoleCode(value) {
      return Object.values(ROLE_CODE).find((v) => v.value === value).label;
    },
    displayRoleCodeType(value) {
      return Object.values(ROLE_CODE).find((v) => v.value === value).type;
    },
    displayTaskName(value) {
      return Object.values(EXPERIENCED_TASK).find((v) => v.value === value)
        .label;
    },
    displayTaskType(value) {
      return Object.values(EXPERIENCED_TASK).find((v) => v.value === value)
        .type;
    },
    displayComponentItem(experience, attr) {
      return experience.project_components
        .filter((v) => v.attribute === attr)
        .map((v) => [v.name, v.version].join(" "))
        .join(", ");
    },
    add() {
      this.form = null;
      this.isFormOpen = true;
    },
    edit(experience) {
      this.form = experience;
      this.isFormOpen = true;
    },
    async remove(experience) {
      this.$buefy.dialog.confirm({
        title: "注意",
        message: `経歴を削除します。この処理は元に戻せません。よろしいですか？`,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.store("my/removeWorkExperience", experience.id);
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    async download() {
      await this.fetch("my/downloadResume");
    },
  },
  async mounted() {
    await this.fetch("my/listWorkExperiences");
  },
};
</script>

<style scoped>
.resume.card-header-title {
  display: block;
}
</style>
